@use "../../../styles/variables" as v;

.mobile-app{
    background-color: v.$color1;
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);

    > div{
        padding: 50px;
    }

    div[class^="col"]{
        display: flex;
        align-items: center;

        &:last-child{
            justify-content: start;

            button{
                margin-left: 10px;
            }


            @media(min-width: 992px) {
                justify-content: end;
            }
        }
    }


}