@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "bootstrap/scss/bootstrap";
@import "sweetalert2/src/variables";
@import 'sweetalert2/src/sweetalert2';

@import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

html {
  scroll-behavior: auto !important;
}


.p-multiselect{
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width:100%;
  margin-bottom:1rem
}