@use "../../styles/variables" as v;

.event-card {
  height: 100% !important;
  cursor: pointer;

  .card-title {
    color: v.$color2;
    margin-top: 30px;
  }

  .card-subtitle {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    color: v.$color1;
    margin-top: 10px;

  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .image {
      overflow: hidden;
      img {
        transition: transform 0.5s;
        -webkit-transition: transform 0.5s;
        -moz-transition: transform 0.5s;
        -ms-transition: transform 0.5s;
        -o-transition: transform 0.5s;

        &:hover {
          transform: scale(1.3);
          -webkit-transform: scale(1.3);
          -moz-transform: scale(1.3);
          -ms-transform: scale(1.3);
          -o-transform: scale(1.3);
        }
      }
    }
  }
}
