@use "../../styles/variables" as v;

.contact-form{
    h2{
        color: v.$color2;
        margin-bottom: 50px;
    }

    .form-control, .input-group-text{
        border:0;
        border-bottom: 1px solid v.$color2-opacity-40;
        background: none;
        color: v.$color2;
    }

    .form-control::placeholder{
        color: v.$color2-opacity-40;
    }

}