@use "../../styles/variables" as v;

.page-header{
    background-color: v.$color5;
    color:v.$color4;
    text-align: center;
    padding:50px 0;

    h1{
        font-size: 3rem;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before{
            content:"⚆";
            margin-right: 1rem;
            font-size: 1rem;
        }

        &::after{
            content:"⚆";
            margin-left: 1rem;
            font-size: 1rem;
        }
    }
    
}