@use "../../styles/variables" as v;

.user-menu{

    a{
        color: v.$color4;
        text-decoration: none;
        font-weight: 600;
    }
    
}